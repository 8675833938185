var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('filter-section', {
    attrs: {
      "cols": [3, 3, 3, 3]
    }
  }, [_c('search-filter', {
    attrs: {
      "slot": "col_0"
    },
    slot: "col_0",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('distributor-id-filter', {
    attrs: {
      "slot": "col_1"
    },
    slot: "col_1",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('team-id-filter', {
    attrs: {
      "slot": "col_2",
      "distributor-id": _vm.filters.distributor_id
    },
    slot: "col_2",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  }), _c('user-role-id-filter', {
    attrs: {
      "slot": "col_3"
    },
    slot: "col_3",
    model: {
      value: _vm.filters,
      callback: function callback($$v) {
        _vm.filters = $$v;
      },
      expression: "filters"
    }
  })], 1), _c('v-card', [_c('v-card-text', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', [_c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading,
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.fetchData
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiRefresh) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('common.list.refresh')) + " ")])], 1)], 1), _c('div', [_vm.$can('user:create', 'user_management') ? _c('create-dialog') : _vm._e(), _vm.$can('user:export', 'user_management') ? _c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.exportToCsv
    }
  }, [_c('v-icon', {
    staticClass: "prefix-icon"
  }, [_vm._v(" " + _vm._s(_vm.icons.mdiDatabaseExportOutline) + " ")]), _vm._v(" " + _vm._s(_vm.$t('common.list.export')) + " ")], 1) : _vm._e()], 1)]), _c('user-table', {
    ref: "tableRef",
    attrs: {
      "loading": _vm.loading,
      "users": _vm.users,
      "users-total": _vm.usersTotal
    },
    on: {
      "user-updated": _vm.fetchData,
      "options-updated": _vm.onOptionsUpdated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }